import React from "react";
import styled from "styled-components";
import { Link, StaticQuery, graphql } from "gatsby";

import name from "../images/leszek-bodzioch.png";

const Footer = props => (
  <StaticQuery
    query={graphql`
      query FooterData {
        ProduktyPszczele: allMarkdownRemark(
          filter: { frontmatter: { path: { regex: "/produkty-pszczele/" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                path
              }
            }
          }
        }
        MiodyOdmianowe: allMarkdownRemark(
          filter: { frontmatter: { path: { regex: "/miody-odmianowe/" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                path
              }
            }
          }
        }
      }
    `}
    render={data => (
      <FooterStyled>
        <Wrapper>
          <Columns>
            <Column>
              <ColumnTitle>Lista Kategorii</ColumnTitle>
              <ColumnList>
                <ColumnLi>
                  <LinkStyled to="/miody-odmianowe">Miody odmianowe</LinkStyled>
                </ColumnLi>
                <ColumnLi>
                  <LinkStyled to="/o-nas-w-sieci">O nas w sieci</LinkStyled>
                </ColumnLi>
                <ColumnLi>
                  <LinkStyled to="/produkty-pszczele">
                    Produkty pszczele
                  </LinkStyled>
                </ColumnLi>
              </ColumnList>
              <ColumnTitle>Na skróty:</ColumnTitle>
              <ColumnList>
                <ColumnLi>
                  <LinkStyled to="/jak-spozywac-miod">
                    Jak spożywać miód?
                  </LinkStyled>
                </ColumnLi>
                <ColumnLi>
                  <LinkStyled to="/mikroklimat-ula">Mikroklimat Ula</LinkStyled>
                </ColumnLi>
              </ColumnList>
            </Column>
            <Column>
              <ColumnTitle>Miody Odmianowe</ColumnTitle>
              <ColumnList>
                {data.MiodyOdmianowe.edges.map((item, index) => (
                  <ColumnLi key={index}>
                    <LinkStyled to={item.node.frontmatter.path}>
                      {item.node.frontmatter.title}
                    </LinkStyled>
                  </ColumnLi>
                ))}
              </ColumnList>
            </Column>
            <Column>
              <ColumnTitle>Produkty Pszczele</ColumnTitle>
              <ColumnList>
                {data.ProduktyPszczele.edges.map((item, index) => (
                  <ColumnLi key={index}>
                    <LinkStyled to={item.node.frontmatter.path}>
                      {item.node.frontmatter.title}
                    </LinkStyled>
                  </ColumnLi>
                ))}
              </ColumnList>
            </Column>
            <Column>
              <ColumnTitle>Dane Kontaktowe</ColumnTitle>
              <ColumnList>
                <ColumnLi>Leszek Bodzioch</ColumnLi>
                <ColumnLi>ul. Westerplatte 8/12</ColumnLi>
                <ColumnLi>33-100 Tarnów</ColumnLi>
                <ColumnLi>
                  <A href="tel:0048509212372">tel. 509 212 372</A>
                </ColumnLi>
                <ColumnLi>
                  <A href="tel:0048509991728">tel. 509-991-728</A>
                </ColumnLi>
                <ColumnLi>
                  <A href="mailto:lbodzioch@interia.pl">
                    email: lbodzioch@interia.pl
                  </A>
                </ColumnLi>
              </ColumnList>
              <br />
              <img src={name} alt="Leszek Bodzioch" />
            </Column>
          </Columns>
          <Copyright>
            <div>© 2019 Gospodarstwo Pasieczne - BONIKA</div>
            <Contact>
              <svg viewBox="0 0 20 20">
                <path d="M16.667 13.867a.606.606 0 0 0-.026-.208c-.026-.07-.194-.198-.502-.384a23.139 23.139 0 0 0-1.152-.645l-.69-.377a4.663 4.663 0 0 1-.248-.17 2.006 2.006 0 0 0-.325-.195.66.66 0 0 0-.273-.065c-.157 0-.36.141-.612.423-.252.282-.5.566-.743.853-.243.286-.434.43-.573.43a.637.637 0 0 1-.214-.046 2.041 2.041 0 0 1-.424-.208 5.273 5.273 0 0 1-.182-.11 9.941 9.941 0 0 1-2.22-1.648 9.941 9.941 0 0 1-1.647-2.22 5.273 5.273 0 0 1-.234-.404 2.041 2.041 0 0 1-.085-.202.637.637 0 0 1-.046-.214c0-.113.09-.259.267-.437a5.08 5.08 0 0 1 .586-.5c.213-.157.408-.329.586-.515.178-.187.267-.345.267-.476a.66.66 0 0 0-.065-.273 2.006 2.006 0 0 0-.195-.325 4.663 4.663 0 0 1-.17-.248 27.604 27.604 0 0 0-.52-.964c-.113-.2-.228-.405-.345-.618a5.302 5.302 0 0 0-.326-.527c-.1-.14-.171-.217-.215-.235a.606.606 0 0 0-.208-.026 3.43 3.43 0 0 0-1.315.287c-.4.182-.747.592-1.042 1.23-.295.638-.443 1.205-.443 1.7 0 .138.011.286.033.442.022.156.043.289.065.397a7.157 7.157 0 0 0 .247.814 45.238 45.238 0 0 1 .306.82c.521 1.424 1.46 2.815 2.82 4.174 1.358 1.358 2.749 2.298 4.173 2.819a45.237 45.237 0 0 1 .82.306 7.158 7.158 0 0 0 .814.247c.108.022.24.043.397.065.156.022.304.033.443.033.494 0 1.06-.148 1.699-.443.638-.295 1.048-.642 1.23-1.042a3.43 3.43 0 0 0 .287-1.315zM20 3.75v12.5c0 1.033-.367 1.916-1.1 2.65-.734.733-1.617 1.1-2.65 1.1H3.75c-1.033 0-1.916-.367-2.65-1.1-.733-.734-1.1-1.617-1.1-2.65V3.75C0 2.717.367 1.834 1.1 1.1 1.834.367 2.717 0 3.75 0h12.5c1.033 0 1.916.367 2.65 1.1.733.734 1.1 1.617 1.1 2.65z"></path>
              </svg>
              509 212 372 - Leszek Bodzioch
            </Contact>
          </Copyright>
        </Wrapper>
      </FooterStyled>
    )}
  />
);

const FooterStyled = styled.footer`
  background-color: #640000;
  color: rgba(255, 255, 255, 0.9);
  text-align: left;
  line-height: 3em;
  position: relative;
  margin-top: 100px;

  @media (min-width: 960px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: initial;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
  padding: 30px 10px 0;
`;

const Columns = styled.div`
  display: flex;
  flex-flow: row wrap;
  text-align: center;

  @media (min-width: 481px) {
    text-align: initial;
  }
`;

const Column = styled.div`
  margin-bottom: 40px;
  flex: 1 1 100%;

  @media (min-width: 481px) {
    margin-bottom: 0;
    flex: 1 1 50%;
  }

  @media (min-width: 960px) {
    flex: 1 1 25%;
  }
`;

const ColumnTitle = styled.h3`
  color: #fff;
  display: inline-block;
  border-bottom: 2px solid;
  padding: 0 20px 0 20px;
  line-height: 1.7;
  margin-bottom: 18px;
  text-shadow: 1px 1px 0 #000;
  font-size: 18px;
  white-space: nowrap;

  @media (min-width: 481px) {
    padding: 0 40px 0 0;
  }
`;

const ColumnList = styled.ul`
  list-style: none;
  margin: 0;
`;

const ColumnLi = styled.li`
  margin: 0;
  padding: 0;
  line-height: 1.4;
`;

const LinkStyled = styled(Link)`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
const A = styled.a`
  color: #fff;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const Copyright = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @media (min-width: 960px) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

const Contact = styled.div`
  display: flex;
  align-items: center;

  svg {
    position: relative;
    margin-right: 10px;
    width: 20px;
    height: 20px;

    path {
      fill: #fff;
    }
  }
`;

export default Footer;
