import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import H1 from '../components/H1'

const CategoryProduktyPszczele = (props) => {
  const { totalCount, edges: posts } = props.data.CategoryData
  
  return (
    <Layout>
      <H1>Produkty pszczele ({totalCount})</H1>
      {posts.map((post,index) => {
        const { excerpt, frontmatter: { title, path } } = post.node
        return (
          <PostExcerptWrapper key={index}>
            <Link to={path}>
              <PostExcerptTitle>{title}</PostExcerptTitle>
            </Link>
            <div>{excerpt}</div>
          </PostExcerptWrapper>
        )
      })}
    </Layout>
  )
}

const PostExcerptTitle = styled.h4`
  margin: 0 0 7px 0;
  font-size: 1.5rem;
`

const PostExcerptWrapper = styled.div`
  margin-bottom: 40px;
`

export default CategoryProduktyPszczele

export const query = graphql`
query CategoryProduktyPszczele {
  CategoryData: allMarkdownRemark(filter: { frontmatter: { path: { regex: "/produkty-pszczele/"}}}){
    totalCount
    edges {
      node {
        frontmatter {
          title
          path
        }
        excerpt(pruneLength: 300)
      }
    }
  }
}
`