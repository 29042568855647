import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'



class Header extends Component {

  state = {
    showMenu: false
  }

  handleShowDrawer = (e) => {
    document.body.classList.add("fixed");
    this.setState({ showMenu: true })
  }
  
  handleHideDrawer = (e) => {
    document.body.classList.remove("fixed");
    this.setState({ showMenu: false })
  }

  render(){
    const { showMenu } = this.state
    return (
      <HeaderStyled showMenu={showMenu}>
        <Nav showMenu={showMenu}>
          <Menu showMenu={showMenu} onClick={this.handleShowDrawer}>
            <div></div>
            <div></div>
            <div></div>
          </Menu>
          <List showMenu={showMenu}>
            <Li>
              <LinkStyled
                onClick={this.handleHideDrawer}
                activeStyle={{textDecoration: 'underline'}}
                to="/"
              >Home</LinkStyled>
            </Li>
            <Li>
              <LinkStyled
                onClick={this.handleHideDrawer}
                activeStyle={{textDecoration: 'underline'}}
                to="/o-nas"
              >O Nas</LinkStyled>
            </Li>
            <Li>
              <LinkStyled
                onClick={this.handleHideDrawer}
                activeStyle={{textDecoration: 'underline'}}
                to="/oferta"
              >Oferta</LinkStyled>
            </Li>
            <Li>
              <LinkStyled
                onClick={this.handleHideDrawer}
                activeStyle={{textDecoration: 'underline'}}
                to="/miod-pszczeli"
              >Miód</LinkStyled>
            </Li>
            <Li>
              <LinkStyled
                onClick={this.handleHideDrawer}
                activeStyle={{textDecoration: 'underline'}}
                to="/galeria"
              >Galeria</LinkStyled>
            </Li>
            <Li>
              <LinkStyled
                onClick={this.handleHideDrawer}
                activeStyle={{textDecoration: 'underline'}}
                to="/jak-spozywac-miod"
              >Jak spożywać miód?</LinkStyled>
            </Li>
            <Li>
              <LinkStyled
                onClick={this.handleHideDrawer}
                activeStyle={{textDecoration: 'underline'}}
                to="/kontakt"
              >Kontakt</LinkStyled>
            </Li>
          </List>
          <Close showMenu={showMenu} onClick={this.handleHideDrawer}>
            <div></div>
            <div></div>
          </Close>
        </Nav>
      </HeaderStyled>
    )
  }
}


const HeaderStyled = styled.header`
  background-color: #640000;
  position: fixed;
  height: ${p => p.showMenu ?  "initial" : "64px"};
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: ${p => p.showMenu ? "0" : "initial"};
  box-shadow: 0 0 10px rgba(0,0,0,.3);
  
  @media (min-width: 960px) {
    height: 64px;
    bottom: 0;
  }
`

const Menu = styled.div`
  width: 25px;
  height: 25px;
  display: ${p => p.showMenu ? "none" : "flex"};
  flex-flow: column wrap;
  justify-content: space-around;
  cursor: pointer;
  opacity: .8;
  
  &:hover {}
  &:hover:active {
    opacity: 1;
  }
 
  div {
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: #fff;
  }

  @media (min-width: 960px) {
    display: none;
  }
`

const Close = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  opacity: .8;
  cursor: pointer;
  top: 20px;
  right: 20px;

  display: ${p => p.showMenu ? "block" : "none"};

  div {
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: #fff;

    &:first-child {
      transform: translateY(13px) rotate(45deg);
      transform-origin: 50%;
      position: absolute;
    }
    &:last-child {
      transform: translateY(13px) rotate(-45deg);
      transform-origin: 50% 50%;
      position: absolute;
    }
  }
`

const Nav = styled.nav`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 940px;
  padding: 0 10px;

  display: flex;
  align-items: ${p => p.showMenu ? "flex-start" : "center"};
  justify-content: flex-start;
`
const List = styled.ul`
  display:  ${p => p.showMenu ? "flex" : "none"};
  justify-content: flex-start;
  flex-flow: ${p => p.showMenu ? "column" : "row"};
  min-height: initial;
  list-style: none;
  width: 960px;
  align-items: initial;
  margin: 0 auto;
  
  @media (min-width: 960px) {
    display: flex;
  }

  a {
    line-height: ${p => p.showMenu ? 2.7 : 4}em;
  }
`

const Li = styled.li`
  margin: 0;
`
 
const LinkStyled = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  padding: 0px 5px;
  margin: 5px;
  color: #fff;
  border-bottom: 1px solid transparent;
  font-size: 1em;
  font-family: 'Kalam', cursive;
`

export default Header
